import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar } from '@material-ui/core';
import { Toolbar } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import { authenticateUser } from '../service/auth';

const headerStyles = makeStyles({
  logo: {
    color: 'white',
    fontWeight: 600,
    fontSize: 30,
    textAlign: 'left',
    fontFamily: 'Arial',
    display: 'inline',
    textDecoration: 'none',
  },

  appbar: {
    position: 'sticky',
    top: 0,
    height: '70px',
    backgroundColor: 'orange',
    color: 'white',
    float: 'left',
    paddingRight: '60px',
    paddingLeft: '100px',
  },

  toolbar: {
    backgroundColor: 'orange',
    color: 'white',
    justifyContent: 'space-between',
  },
  navigationMenu: {
    display: 'flex',
  },
  pageLinks: {
    marginLeft: '38px',
    fontFamily: 'Arial',
    fontSize: 15,
    color: 'white',
    display: 'inline',
    padding: '14px 20px',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'white',
      color: 'orange',
      fontWeight: 'bold',
      textDecoration: 'none',
    },
    '&:current': {
      backgroundColor: 'aqua',
      textDecoration: 'none',
    },
    '&:active': {
      backgroundColor: 'black',
    },
  },

  spacer: {
    width: '100%',
    height: '80px',
  },
});

const Header = ({ history }) => {
  const userLoggedIn = authenticateUser();
  const classes = headerStyles();
  const [isUser, setIsUser] = useState(false);

  const isAdmin = localStorage.getItem('QBUser')
    ? JSON.parse(localStorage.getItem('QBUser')).isAdmin
    : false;

  useEffect(() => {
    if (userLoggedIn) {
      setIsUser(true);
    }
  }, []);
  // logout user by removing data from localStorage
  const logoutHandler = () => {
    localStorage.removeItem('QBUser');
    window.location.replace('/login');
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const displayToolbar = () => {
    return (
      <Toolbar className={classes.toolbar}>
        {logo}
        <div className={classes.navigationMenu}>
          <Button
            component={Link}
            className={classes.pageLinks}
            to={'/questions'}
          >
            Export Questions
          </Button>
          <Button to={'/upload'} component={Link} className={classes.pageLinks}>
            Import Questions
          </Button>
          <Button
            to={'/instruction'}
            component={Link}
            className={classes.pageLinks}
          >
            Instructions
          </Button>
          {isAdmin &&
            <Button to={'/users'} component={Link} className={classes.pageLinks}>
              Users
            </Button>
          }

          {isUser ? (
            <div>
              <Button
                className={classes.pageLinks}
                aria-controls="profile-menu"
                aria-haspopup="true"
                onClick={handleMenuClick}
              >
                Profile
              </Button>
              <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={handleMenuClose}
                  component={Link}
                  to={'/user/profile'}
                >
                  My account
                </MenuItem>
                <MenuItem
                  onClick={handleMenuClose}
                  component={Link}
                  to={'/user/change-password'}
                >
                  Change Password
                </MenuItem>
                <MenuItem onClick={handleMenuClose} onClick={logoutHandler}>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <Button
              to={'/login'}
              component={Link}
              className={classes.pageLinks}
            >
              Login
            </Button>
          )}
        </div>
      </Toolbar>
    );
  };

  //create sample logo with link back to home page
  const logo = (
    <Typography variant="h6" component="h1" className={classes.logo}>
      <Link to={'/'} className={classes.logo}>
        Computer Science Quiz Question Bank
      </Link>
    </Typography>
  );

  return (
    <header>
      <AppBar className={classes.appbar}>{displayToolbar()}</AppBar>
      <div className={classes.spacer}></div>
    </header>
  );
};

export default Header;
