import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const NotificationBox = ({
  isOpen,
  OnCloseHandler,
  alertType,
  alertMessage,
}) => {
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isOpen}
        autoHideDuration={6000}
        onClose={OnCloseHandler}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={alertType}
          onClose={OnCloseHandler}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default NotificationBox;
