import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const UserRow = ({ userData, rowIndex }) => {
  const classes = useRowStyles();
  const rowCount = rowIndex +1
  const formatStringFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root} hover>
        <TableCell component="th" scope="row">
          {rowCount}
        </TableCell>
        <TableCell>
          {formatStringFirstLetter(userData.firstName) + ' ' + formatStringFirstLetter(userData.lastName)}
        </TableCell>
        <TableCell>{userData.email}</TableCell>
        <TableCell>{userData.university}</TableCell>
        <TableCell>{userData.isAdmin ? 'Yes' : 'No'}</TableCell>
        <TableCell>{userData.isVerified ? 'Yes' : 'No'}</TableCell>
        <TableCell>
          <IconButton
            aria-label="user details"
            size="small"
            component={Link}
            to={
              `/users/${userData.id}`
            }
          >
            User Details
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default UserRow;