import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { authenticateUser } from '../service/auth';
import { useParams } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import NotificationBox from '../components/NotificationBox';

const userDetailsPageStyles = makeStyles({
  pageContainer: {
    margin: '20px',
    paddingBottom: '20px',
    textAlign: 'left',
  },
  spacer: {
    width: '100%',
    height: '30px',
  },
});

const initialState = {
  users: [],
  confirmDialog: false,
};

const UserDetailsPage = ({ history }) => {
  const classes = userDetailsPageStyles();
  const [state, setState] = useState(initialState);
  const { userID } = useParams(); //extracts user ID from URL

  const userLoggedIn = authenticateUser();
  if (!userLoggedIn) {
    history.push('/login');
  }

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    if (
      !localStorage.getItem('QBUser') ||
      !JSON.parse(localStorage.getItem('QBUser')).id
    ) {
      return;
    }
    let user_id = JSON.parse(localStorage.getItem('QBUser')).id;
    const response = await fetch(`/api/admin/users/${userID}`, {
      headers: { id: user_id },
    });
    const data = await response.json();
    console.log(data.user);
    setState({
      ...state,
      ...data.user,
    });
  };

  const verifyUserButtonClick = async () => {
    setState({
      ...state,
      confirmDialog: true,
    });
  };

  const handleDialogCancel = async (answer) => {
    setState({
      ...state,
      confirmDialog: false,
    });
  };

  const handleDialogVerify = async (answer) => {
    let user_id = JSON.parse(localStorage.getItem('QBUser')).id;
    const response = await fetch(`/api/admin/verify/${state.id}`, {
      headers: { id: user_id },
    });
    const data = await response.json();

    if (data.success) {
      setState({
        ...state,
        confirmDialog: false,
        isVerified: true,
        successPopup: true,
        successInfo: data.message,
      });
    } else {
      setState({
        ...state,
        confirmDialog: false,
        errorPopup: true,
        errorInfo: data.errorMessage,
      });
    }
  };

  const handleSuccessPopupClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({
      ...state,
      successPopup: false,
    });
  };

  const handleErrorPopupClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({
      ...state,
      errorPopup: false,
    });
  };

  const successPopupBox = (
    <NotificationBox
      isOpen={state.successPopup}
      OnCloseHandler={handleSuccessPopupClose}
      alertType="success"
      alertMessage={state.successInfo}
    />
  );

  const errorPopupBox = (
    <NotificationBox
      isOpen={state.errorPopup}
      OnCloseHandler={handleErrorPopupClose}
      alertType="error"
      alertMessage={state.errorInfo}
    />
  );

  return (
    <div className={classes.pageContainer}>
      {successPopupBox}
      {errorPopupBox}
      <h3>User Details</h3>
      <div>
        <p>User ID: {state.id}</p>
        <p>First Name: {state.firstName}</p>
        <p>Last Name: {state.lastName}</p>
        <p>Associated University: {state.university} </p>
        <p>University Email: {state.email}</p>
        <p>University Association URL: {state.universityPageURL}</p>
        {state.isAdmin ? <p>Role: Admin</p> : null}
        {state.isVerified ? (
          <p>Verified: Yes</p>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={verifyUserButtonClick}
          >
            Verify User
          </Button>
        )}
      </div>

      <Dialog
        open={state.confirmDialog}
        onClose={handleDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Confirm Verify User?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Click 'Verify' to verify this account, or 'Cancel'.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDialogVerify} color="primary" autoFocus>
            Verify
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserDetailsPage;
