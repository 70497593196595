import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { PROGRAMMING_LANGUAGES, QUESTION_TOPICS } from '../../consts';

const dropdownMenuStyles = makeStyles({
  menuContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },

  selectBox: {
    width: '120px',
    height: '40px',
    padding: '10px',
    marginLeft: '20px',
    marginRight: '20px',
  },
});

const PROGRAMMING_LANGUAGE_ARRAY = Object.entries(PROGRAMMING_LANGUAGES);
const QUESTION_TOPICS_ARRAY = Object.entries(QUESTION_TOPICS);

const DropdownMenu = ({
  language,
  changeLanguageHandler,
  questionTopic,
  changeTopicHandler,
}) => {
  const classes = dropdownMenuStyles();

  return (
    <div className={classes.menuContainer}>
      <div>
        Programming Language
        <select
          className={classes.selectBox}
          onChange={changeLanguageHandler}
          value={language}
        >
          <option key={'none'} value={'none'}>
          Choose
          </option>
          {PROGRAMMING_LANGUAGE_ARRAY.map(([key, programmingLanguage]) => {
            if (key !== 'all') {
              return (
                <option key={key} value={key}>
                  {programmingLanguage}
                </option>
              );
            } else return null;
          })}
        </select>
      </div>
      <div>
        Question Topic
        <select
          className={classes.selectBox}
          onChange={changeTopicHandler}
          value={questionTopic}
        >
          <option key={'none'} value={'none'}>
          Choose
          </option>
          {QUESTION_TOPICS_ARRAY.map(([key, queTopic]) => {
            if (key !== 'all') {
              return (
                <option key={key} value={key}>
                  {queTopic}
                </option>
              );
            } else return null;
          })}
        </select>
      </div>
    </div>
  );
};

export default DropdownMenu;
