import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import NotificationBox from '../components/NotificationBox';
import { Link } from 'react-router-dom';
import axios from 'axios';

const ForgotPasswordStyles = makeStyles({
  pageContainer: {
    margin: '20px',
    marginLeft: '90px',
    paddingBottom: '20px',
  },
  form: {
    maxWidth: '350px',
  },
  label: {
    lineHeight: '2',
    textAlign: 'left',
    display: 'block',
    marginBottom: '13px',
    marginTop: '20px',
    color: 'black',
    fontSize: '16px',
    fontWeight: '700',
  },
  submitButton: {
    display: 'block',
    background: 'orange',
    color: 'white',
    textTransform: 'uppercase',
    cursor: 'pointer',
    border: 'none',
    marginTop: '25px',
    marginBottom: '20px',
    padding: '12px',
    fontSize: '15px',
    fontWeight: '600',
  },
  inputBox: {
    display: 'block',
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: '4px',
    border: '1px solid black',
    padding: '10px 15px',
    marginBottom: '10px',
    fontSize: '16px',
  },
  errorInfo: {
    color: 'red',
  },
});

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [state, setState] = useState({});
  const classes = ForgotPasswordStyles();

  const onSubmit = async (userData, e) => {
    const data = {
      email: userData.email,
    };

    const res = await axios({
      url: 'api/user/forgot-password',
      method: 'POST',
      data: data,
    });

    if (res.data.success) {
      setState({
        ...state,
        successInfo:
          'Please check your inbox for a link to reset your password.',
        successPopup: true,
      });
      e.target.reset();
    } else {
      if (res.data == null) {
        setState({
          ...state,
          errorInfo: 'Error with request, please try again.',
          errorPopup: true,
        });
        return;
      }
      setState({
        ...state,
        errorInfo: res.data.errorMessage,
        errorPopup: true,
      });
    }
  };

  const handleSuccessPopupClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({
      ...state,
      successPopup: false,
    });
  };

  const handleErrorPopupClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({
      ...state,
      errorPopup: false,
    });
  };

  const successPopupBox = (
    <NotificationBox
      isOpen={state.successPopup}
      OnCloseHandler={handleSuccessPopupClose}
      alertType="success"
      alertMessage={state.successInfo}
    />
  );

  const errorPopupBox = (
    <NotificationBox
      isOpen={state.errorPopup}
      OnCloseHandler={handleErrorPopupClose}
      alertType="error"
      alertMessage={state.errorInfo}
    />
  );

  return (
    <div
      className={classes.pageContainer}
      success={state.formSuccess}
      error={state.formError}
    >
      {successPopupBox}
      {errorPopupBox}

      <h3>Forgot your password?</h3>

      <p>
        Please enter your email address below to receive a link to reset your
        password.
      </p>

      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <label className={classes.label}>Email:</label>
        <input
          className={classes.inputBox}
          type="email"
          {...register('email', { required: true })}
        />
        {errors?.email?.type === 'required' && (
          <em className={classes.errorInfo}>
            Your email is required to reset your account password.
          </em>
        )}

        <button
          className={classes.submitButton}
          type="submit"
          onClick={handleSubmit}
        >
          Request Password Reset
        </button>
        <Link to="/login">Back to Login</Link>
      </form>
    </div>
  );
};

export default ForgotPassword;
