import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import Checkbox from '@material-ui/core/Checkbox';
import { QUESTION_TOPICS } from '../../consts';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  lowerCaseButton: { textTransform: "none" },
});

const QuestionRow = ({ question, onCheckboxClick, isChecked}) => {
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  const formatStringFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root} hover>
        <TableCell padding="checkbox">
          <Checkbox
            onChange={onCheckboxClick}
            inputProps={{ 'aria-label': 'select individual question' }}
            checked={isChecked}
          />
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          dangerouslySetInnerHTML={{ __html: question.questionText }}
        >
          {/* {question.questionText} */}
        </TableCell>
        <TableCell>{question.questionType}</TableCell>

        {question.questionType === 'MC' && (
          <TableCell
            dangerouslySetInnerHTML={{ __html: question.mcAnswer }}
          ></TableCell>
        )}

        {question.questionType === 'TF' && (
          <TableCell>
            {question.tfAnswer === true
              ? 'True'
              : question.tfAnswer === false
              ? 'False'
              : null}
          </TableCell>
        )}

        {question.questionType === 'LA' && (
          <TableCell>
            {'N/A'}
          </TableCell>
        )}

        <TableCell>
          {formatStringFirstLetter(question.programmingLanguage)}
        </TableCell>
        <TableCell>{QUESTION_TOPICS[question.topic]}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? 'Hide Details' : 'Show Details'}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Additional Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {question.MCAnswerOptions.length > 0 && (
                      <TableCell>Answer Options</TableCell>
                    )}
                    {question.MCAnswerOptions.length > 0 &&
                      !question.MCAnswerOptions.every((a) => !a.feedback) && (
                        <TableCell>Answer Option Feedback</TableCell>
                      )}
                    {question.generalFeedback && (
                      <TableCell>General Feedback</TableCell>
                    )}
                    {question.correctFeedback && (
                      <TableCell>Correct Answer Feedback</TableCell>
                    )}
                    {question.incorrectFeedback && (
                      <TableCell>Incorrect Answer Feedback</TableCell>
                    )}
                    {question.tfTrueFeedback && (
                      <TableCell>True Answer Feedback</TableCell>
                    )}
                    {question.tfFalseFeedback && (
                      <TableCell>False Answer Feedback</TableCell>
                    )}
                    {question.hint && (
                      <TableCell>Hint</TableCell>
                    )}
                    <TableCell>Grade</TableCell>
                    <TableCell>Difficulty</TableCell>
                    <TableCell>Uploaded on</TableCell>
                    {/*(isAdmin || userId === question.createdBy) && (
                      <TableCell>Manage</TableCell>
                    )*/}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {question.MCAnswerOptions.length > 0 && (
                      <TableCell component="th" scope="row">
                        <ol type="a">
                          {question.MCAnswerOptions.map((ansOption, key) => (
                            <li
                              key={key}
                              dangerouslySetInnerHTML={{
                                __html: ansOption.answerText,
                              }}
                            ></li>
                          ))}
                        </ol>
                      </TableCell>
                    )}
                    {question.MCAnswerOptions.length > 0 &&
                      !question.MCAnswerOptions.every((a) => !a.feedback) && (
                        <TableCell component="th" scope="row">
                          <ol type="a">
                            {question.MCAnswerOptions.map((ansOption, key) => (
                              <li
                                key={key}
                                dangerouslySetInnerHTML={{
                                  __html: ansOption.feedback? ansOption.feedback : '-',
                                }}
                              ></li>
                            ))}
                          </ol>
                        </TableCell>
                      )}
                    {question.generalFeedback && (
                      <TableCell
                        component="th"
                        scope="row"
                        dangerouslySetInnerHTML={{
                          __html: question.generalFeedback,
                        }}
                      ></TableCell>
                    )}

                    {question.correctFeedback && (
                      <TableCell
                        component="th"
                        scope="row"
                        dangerouslySetInnerHTML={{
                          __html: question.correctFeedback,
                        }}
                      ></TableCell>
                    )}
                    {question.incorrectFeedback && (
                      <TableCell
                        component="th"
                        scope="row"
                        dangerouslySetInnerHTML={{
                          __html: question.incorrectFeedback,
                        }}
                      ></TableCell>
                    )}
                    {question.tfTrueFeedback && (
                      <TableCell
                        component="th"
                        scope="row"
                        dangerouslySetInnerHTML={{
                          __html: question.tfTrueFeedback,
                        }}
                      ></TableCell>
                    )}
                    {question.tfFalseFeedback && (
                      <TableCell
                        component="th"
                        scope="row"
                        dangerouslySetInnerHTML={{
                          __html: question.tfFalseFeedback,
                        }}
                      ></TableCell>
                    )}
                    {question.hint && (
                      <TableCell
                        component="th"
                        scope="row"
                        dangerouslySetInnerHTML={{
                          __html: question.hint ? question.hint : '-',
                        }}
                      ></TableCell>
                    )}
                    <TableCell component="th" scope="row">
                      {question.grade}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {question.difficulty}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {moment(question.createdAt).format('DD-MM-YYYY HH:MM')}
                    </TableCell>
                    {/*(isAdmin || userId === question.createdBy) && (
                      <TableCell component="th" scope="row">
                        <Button 
                          onClick={handleDeleteConfirmation}
                          variant="contained" 
                          style={{
                            backgroundColor: "#F44336",
                            color: "white",
                          }}
                          classes={{ label: classes.lowerCaseButton }}
                        >
                          Delete Question
                        </Button>
                      </TableCell>
                        )*/}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default QuestionRow;
