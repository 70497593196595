import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import NotificationBox from '../components/NotificationBox';
import { Link, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';

const ResetPasswordStyles = makeStyles({
  pageContainer: {
    margin: '20px',
    marginLeft: '90px',
    paddingBottom: '20px',
  },
  form: {
    maxWidth: '350px',
  },
  label: {
    lineHeight: '2',
    textAlign: 'left',
    display: 'block',
    marginBottom: '13px',
    marginTop: '20px',
    color: 'black',
    fontSize: '16px',
    fontWeight: '700',
  },
  submitButton: {
    display: 'block',
    background: 'orange',
    color: 'white',
    textTransform: 'uppercase',
    cursor: 'pointer',
    border: 'none',
    marginTop: '25px',
    marginBottom: '20px',
    padding: '12px',
    fontSize: '15px',
    fontWeight: '600',
  },
  inputBox: {
    display: 'block',
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: '4px',
    border: '1px solid black',
    padding: '10px 15px',
    marginBottom: '10px',
    fontSize: '16px',
  },
  errorInfo: {
    color: 'red',
  },
});

const ResetPassword = ({ history }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [state, setState] = useState({});
  const classes = ResetPasswordStyles();

  let resetCode = new URLSearchParams(useLocation().search).get('id');

  const onSubmit = async (userData, e) => {
    const data = {
      password: userData.password,
      resetCode: resetCode,
    };

    if (data.password.length < 8) {
      setState({
        ...state,
        errorInfo: 'Password must be at least 8 characters long.',
        errorPopup: true,
      });
      return;
    }

    const res = await axios({
      url: 'api/user/reset-password',
      method: 'POST',
      data: data,
    });

    if (res.data == null) {
      setState({
        ...state,
        errorInfo: 'Error with request, please try again.',
        errorPopup: true,
      });
      return;
    }

    if (res.data.success) {
      setState({
        ...state,
        successInfo: 'Password reset successfully. You can now login',
        successPopup: true,
      });
      e.target.reset();
      setTimeout(() => {
        history.push('/login');
      }, 3000);
    } else {
      setState({
        ...state,
        errorInfo: res.data.errorMessage,
        errorPopup: true,
      });
    }
  };

  const handleSuccessPopupClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({
      ...state,
      successPopup: false,
    });
  };

  const handleErrorPopupClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({
      ...state,
      errorPopup: false,
    });
  };

  const successPopupBox = (
    <NotificationBox
      isOpen={state.successPopup}
      OnCloseHandler={handleSuccessPopupClose}
      alertType="success"
      alertMessage={state.successInfo}
    />
  );

  const errorPopupBox = (
    <NotificationBox
      isOpen={state.errorPopup}
      OnCloseHandler={handleErrorPopupClose}
      alertType="error"
      alertMessage={state.errorInfo}
    />
  );

  return (
    <div
      className={classes.pageContainer}
      success={state.formSuccess}
      error={state.formError}
    >
      {successPopupBox}
      {errorPopupBox}

      <h3>Reset your password</h3>

      <p>Enter your new password below.</p>

      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <label className={classes.label}>Password:</label>
        <input
          className={classes.inputBox}
          type="password"
          {...register('password', { required: true })}
        />
        {errors?.email?.type === 'required' && (
          <em className={classes.errorInfo}>
            Your email is required to reset your account password.
          </em>
        )}

        <button
          className={classes.submitButton}
          type="submit"
          onClick={handleSubmit}
        >
          Reset Password
        </button>
        <Link to="/login">Back to Login</Link>
      </form>
    </div>
  );
};

export default ResetPassword;
