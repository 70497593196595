import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';
import { Typography } from '@material-ui/core';

const questionSelectStyles = makeStyles({
  selectedToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#5f5d5d',
  },
});

const QuestionSelectToolbar = ({
  selectedQuestionsCount,
  totalQuestionsCount,
}) => {
  const classes = questionSelectStyles();

  return (
    <Toolbar
      className={clsx(classes.selectedToolbar, {
        [classes.highlight]: selectedQuestionsCount > 0,
      })}
    >
      <Typography color="inherit" variant="subtitle1" component="div">
        {selectedQuestionsCount} questions selected
      </Typography>
      <Typography color="inherit" variant="subtitle1" component="div">
        total questions {totalQuestionsCount}
      </Typography>
    </Toolbar>
  );
};

export default QuestionSelectToolbar;
