import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';

import QuestionsPage from './pages/questions';
import UploadQuestionsPage from './pages/upload';
import InstructionPage from './pages/instruction';
import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import UsersPage from './pages/users';
import UserDetailPage from './pages/userDetails';
import ForgotPassword from './pages/forgotPassword';
import ResetPasswordPage from './pages/resetPassword';
import ChangePasswordPage from './pages/changePassword';
import ProfilePage from './pages/profile';

import './App.css';

const App = () => {
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={QuestionsPage} />
          <Route exact path="/questions" component={QuestionsPage} />
          <Route exact path="/upload" component={UploadQuestionsPage} />
          <Route exact path="/instruction" component={InstructionPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/users" component={UsersPage} />
          <Route path="/users/:userID" component={UserDetailPage} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPasswordPage} />
          <Route
            exact
            path="/user/change-password"
            component={ChangePasswordPage}
          />
          <Route exact path="/user/profile" component={ProfilePage} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
