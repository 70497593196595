import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  QUESTION_TYPES,
  PROGRAMMING_LANGUAGES,
  QUESTION_TOPICS,
} from '../../consts';
import Checkbox from '@material-ui/core/Checkbox';

const questionFilterStyles = makeStyles({
  filtersContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  selectBox: {
    width: '120px',
    height: '40px',
    padding: '10px',
    marginLeft: '10px',
    marginRight: '20px',
  },
});

const QUESTION_TYPE_ARRAY = Object.entries(QUESTION_TYPES);
const PROGRAMMING_LANGUAGE_ARRAY = Object.entries(PROGRAMMING_LANGUAGES);
const QUESTION_TOPICS_ARRAY = Object.entries(QUESTION_TOPICS);

const QuestionFilter = ({
  questionType,
  changeQuestionTypeHandler,
  programmingLanguage,
  changeLanguageHandler,
  questionTopic,
  changeTopicHandler,
  uploadedByMeChecked,
  changeUploadedByMeHandler,
  userId,
}) => {
  const classes = questionFilterStyles();

  return (
    <div className={classes.filtersContainer}>
      <div>
        Question Type
        <select
          className={classes.selectBox}
          onChange={changeQuestionTypeHandler}
          value={questionType}
        >
          {QUESTION_TYPE_ARRAY.map(([key, queType]) => {
            return (
              <option key={key} value={key}>
                {queType}
              </option>
            );
          })}
        </select>
      </div>

      <div>
        Programming Language
        <select
          className={classes.selectBox}
          onChange={changeLanguageHandler}
          value={programmingLanguage}
        >
          {PROGRAMMING_LANGUAGE_ARRAY.map(([key, progLanguage]) => {
            return (
              <option key={key} value={key}>
                {progLanguage}
              </option>
            );
          })}
        </select>
      </div>

      <div>
        Question Topic
        <select
          className={classes.selectBox}
          onChange={changeTopicHandler}
          value={questionTopic}
        >
          {QUESTION_TOPICS_ARRAY.map(([key, queTopic]) => {
            return (
              <option key={key} value={key}>
                {queTopic}
              </option>
            );
          })}
        </select>
      </div>

      <div>
        Uploaded By Me
        <Checkbox
          onChange={changeUploadedByMeHandler}
          value={uploadedByMeChecked}
        />
      </div>
    </div>
  );
};

export default QuestionFilter;
