import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import NotificationBox from '../components/NotificationBox';
import { registerUser, authenticateUser } from '../service/auth';
import { ASSOCIATED_UNIVERSITIES } from '../consts';

const registerFormStyles = makeStyles({
  pageContainer: {
    margin: '20px',
    marginLeft: '90px',
    paddingBottom: '20px',
  },
  container: {
    display: 'flex',
    textAlign: 'center',
  },
  item: {
    flexBasis: '35%',
    maxWidth: '35%',
    margin: '20px',
  },
  gridSpacer: {
    flexBasis: '12.5%',
    maxWidth: '12.5%',
  },
  gridSpacerMiddle: {
    flexBasis: '5%',
    maxWidth: '5%',
  },
  registerFormContainer: {
    //borderRight: '2px solid black',
    textAlign: 'left',
  },
  form: {
    maxWidth: '500px',
    textAlign: 'left',
  },
  leftAlign: {
    textAlign: 'left',
  },
  rightAlign: {
    textAlign: 'right',
  },
  label: {
    lineHeight: '2',
    textAlign: 'left',
    display: 'block',
    marginBottom: '13px',
    marginTop: '20px',
    color: 'black',
    fontSize: '16px',
    fontWeight: '700',
  },
  checkboxLabel: {
    lineHeight: '2',
    textAlign: 'left',
    display: 'inline',
    marginBottom: '13px',
    marginTop: '20px',
    color: 'black',
    fontSize: '16px',
    fontWeight: '700',
  },
  submitButton: {
    display: 'block',
    background: 'orange',
    color: 'white',
    textTransform: 'uppercase',
    cursor: 'pointer',
    border: 'none',
    marginTop: '20px',
    padding: '15px',
    fontSize: '18px',
    fontWeight: '600',
  },
  inputBox: {
    display: 'block',
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: '4px',
    border: '1px solid black',
    padding: '10px 15px',
    marginBottom: '10px',
    fontSize: '16px',
  },
  spacer: {
    width: '100%',
    height: '10px',
  },
  errorInfo: {
    color: 'red',
  },
});

const ASSOCIATED_UNIVERSITIES_ARRAY = Object.entries(ASSOCIATED_UNIVERSITIES);

const Register = ({ history }) => {
  const userLoggedIn = authenticateUser();
  if (userLoggedIn) {
    history.push('/questions');
  }

  const {
    university,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [state, setState] = useState({});
  const classes = registerFormStyles();

  const onSubmit = async (userData, e) => {
    const registerData = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.universityEmail,
      university: userData.associatedUniversity,
      universityPageURL: userData.urlAssociation,
    };
    const res = await registerUser(registerData);
    if (res.data.success) {
      setState({
        ...state,
        successInfo:
          'Registration request submitted successfully. You will receive an email of the instruction to login upon your account verification.',
        successPopup: true,
      });
      e.target.reset();
    } else {
      if (res.data == null) {
        setState({
          ...state,
          errorInfo: 'Error in regestration request, please try again.',
          errorPopup: true,
        });
        return;
      }
      setState({
        ...state,
        errorInfo: res.data.errorMessage,
        errorPopup: true,
      });
    }
  };

  const changeUniversityHandler = (e) => {
    setState({
      ...state,
      university: e.target.value,
    });
  };

  const handleSuccessPopupClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({
      ...state,
      successPopup: false,
    });
  };

  const handleErrorPopupClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({
      ...state,
      errorPopup: false,
    });
  };

  const successPopupBox = (
    <NotificationBox
      isOpen={state.successPopup}
      OnCloseHandler={handleSuccessPopupClose}
      alertType="success"
      alertMessage={state.successInfo}
    />
  );

  const errorPopupBox = (
    <NotificationBox
      isOpen={state.errorPopup}
      OnCloseHandler={handleErrorPopupClose}
      alertType="error"
      alertMessage={'Error with registration request. ' + state.errorInfo}
    />
  );

  return (
    <div
      className={classes.pageContainer}
      success={state.formSuccess}
      error={state.formError}
    >
      {successPopupBox}
      {errorPopupBox}

      <h2>Computer Science Quiz Question Bank</h2>

      <div className={classes.spacer}></div>

      <div className={classes.container}>
        <div className={classes.gridSpacer}></div>

        <div className={classes.item}>
          <div className={classes.leftAlign}>
            <h3>About Computer Science Quiz Question Bank</h3>
            <p>
              <b>Computer Science Quiz Question Bank</b> is a repository of quiz
              questions for CS faculty in Canada.
            </p>
            <p>
              The project was funded by the{' '}
              <Link
                to={{ pathname: 'https://www.ecampusontario.ca/' }}
                target="_blank"
              >
                eCampus Ontario Virtual Learning Strategy (VLS)
              </Link>{' '}
            </p>
            <p>
              This app supports transferring questions to and from select
              Learning Management Systems.
            </p>
            <p>
              Visit the <Link to="/instruction">instructions</Link> page for
              answers to frequent questions.
            </p>
          </div>
        </div>

        <div className={classes.gridSpacerMiddle}></div>

        <div className={classes.item}>
          <div className={classes.leftAlign}>
            <h3>Register</h3>
            <p>
              Register below to export and import Computer Science Quiz
              Questions contributed by other professors across Canada.
            </p>
            <p>
              Already have an account? <Link to="/login">Login</Link>
            </p>
          </div>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <label className={classes.label}>First Name:</label>
            <input
              className={classes.inputBox}
              {...register('firstName', {
                required: true,
                maxLength: 20,
                pattern: /^[A-Za-z -]+$/i,
              })}
            />
            {errors?.firstName?.type === 'required' && (
              <em className={classes.errorInfo}>First name is required</em>
            )}
            {errors?.firstName?.type === 'maxLength' && (
              <em className={classes.errorInfo}>
                First name cannot exceed 20 characters
              </em>
            )}
            {errors?.firstName?.type === 'pattern' && (
              <em className={classes.errorInfo}>
                Alphabetical characters, spaces, and hyphens only
              </em>
            )}
            <label className={classes.label}>Last Name:</label>
            <input
              className={classes.inputBox}
              {...register('lastName', {
                required: true,
                maxLength: 20,
                pattern: /^[A-Za-z -]+$/i,
              })}
            />
            {errors?.lastName?.type === 'required' && (
              <em className={classes.errorInfo}>Last name is required</em>
            )}
            {errors?.lastName?.type === 'maxLength' && (
              <em className={classes.errorInfo}>
                Last name cannot exceed 20 characters
              </em>
            )}
            {errors?.lastName?.type === 'pattern' && (
              <em className={classes.errorInfo}>
                Alphabetical characters, spaces, and hyphens only
              </em>
            )}
            <label className={classes.label}>Associated University:</label>
            <select
              className={classes.inputBox}
              onChange={changeUniversityHandler}
              value={university}
              {...register('associatedUniversity', { required: true })}
            >
              <option key={'none'} value={null} />
              {ASSOCIATED_UNIVERSITIES_ARRAY.map(
                ([key, associatedUniversity]) => {
                  if (key !== 'all') {
                    return (
                      <option key={key} value={associatedUniversity}>
                        {associatedUniversity}
                      </option>
                    );
                  } else return null;
                }
              )}
            </select>
            {errors?.associatedUniversity?.type === 'required' && (
              <em className={classes.errorInfo}>
                Associated University is required
              </em>
            )}
            <label className={classes.label}>University Email:</label>
            <p>
              The email address associated with your university must be used to
              verify your access to the question bank
            </p>
            <input
              className={classes.inputBox}
              type="email"
              {...register('universityEmail', { required: true })}
            />
            {errors?.universityEmail?.type === 'required' && (
              <em className={classes.errorInfo}>Email is required</em>
            )}
            <label className={classes.label}>University Association URL:</label>
            <p>
              Please provide a URL to verify your association with your selected
              university (faculty list, course page, etc.)
            </p>
            <input
              className={classes.inputBox}
              type="url"
              {...register('urlAssociation', { required: true })}
            />
            {errors?.urlAssociation?.type === 'required' && (
              <em className={classes.errorInfo}>URL is required</em>
            )}
            <p>
              I understand that any materials uploaded to this site will be
              shared with instructors from other Colleges or Universities who
              may modify and use the content in their courses. I declare that I
              own, or have permission to share, the materials that I am
              uploading.
            </p>
            <input
              name="acceptTerms"
              type="checkbox"
              {...register('acceptTerms', { required: true })}
            />
            <label className={classes.checkboxLabel} htmlFor="acceptTerms">
              Accept Terms & Conditions
            </label>
            {errors?.acceptTerms?.type === 'required' && (
              <em className={classes.errorInfo}>
                You must accept the terms & conditions
              </em>
            )}
            <button
              className={classes.submitButton}
              type="submit"
              onClick={handleSubmit}
            >
              Register
            </button>
          </form>
        </div>

        <div className={classes.gridSpacer}></div>
      </div>
    </div>
  );
};

export default Register;
