import axios from 'axios';

export const login = async (postParametersObj) => {
  const res = await axios({
    url: 'api/login',
    method: 'POST',
    data: postParametersObj,
  });

  return res;
};

export const registerUser = async (postParametersObj) => {
  const res = await axios({
    url: 'api/register',
    method: 'POST',
    data: postParametersObj,
  });

  return res;
};

export const authenticateUser = () => {
  const userObj = localStorage.getItem('QBUser');
  const user = JSON.parse(userObj);
  if (user && user?.id) {
    return true;
  } else {
    return false;
  }
};

export const changePassword = async (postParametersObj) => {
  const res = await axios({
    url: '../api/user/change-password',
    method: 'POST',
    data: postParametersObj,
  });

  return res;
};
