import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { authenticateUser } from '../service/auth';

const ProfilePageStyles = makeStyles({
  pageContainer: {
    margin: '20px',
    paddingBottom: '20px',
    textAlign: 'left',
  },
});

const initialState = {};

const ProfilePage = ({ history }) => {
  const userLoggedIn = authenticateUser();
  if (!userLoggedIn) {
    history.push('/login');
  }

  const classes = ProfilePageStyles();
  const [state, setState] = useState(initialState);
  const userObj = localStorage.getItem('QBUser');
  const user = JSON.parse(userObj);
  const userDetails = {
    name: user?.firstName + ' ' + user?.lastName,
    email: user?.email,
    university: user?.university,
    universityPageURL: user?.universityPageURL,
    accountCreationDate: user?.createdAt,
    accountStatus: user?.isVerified,
    role: user?.isAdmin,
  };

  useEffect(() => {
    setState(userDetails);
  }, []);
  return (
    <div className={classes.pageContainer}>
      <h3>Your Profile</h3>
      <p>Name: {state.name}</p>
      <p>Registered Email: {state.email}</p>
      <p>University: {state.university}</p>
      <p>University Associated Page: {state.universityPageURL}</p>
      <p>
        Account Created on:{' '}
        {moment(state.accountCreationDate).format('DD-MM-YYYY HH:MM')}
      </p>
      <p>
        Account Status:{' '}
        {state.accountStatus === true || state.role === true
          ? 'Verified'
          : 'Not Verified'}
      </p>
      {state.role === true ? <p>Role: Admin</p> : null}

      <Link to="/user/change-password">Change Password</Link>
    </div>
  );
};

export default ProfilePage;
