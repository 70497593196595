import React, { useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import DropdownMenu from '../components/Upload/DropdownMenu';
import NotificationBox from '../components/NotificationBox';
import { authenticateUser } from '../service/auth';
import { IMPORT_TYPES } from '../consts';

const uploadQuestionsStyles = makeStyles({
  pageContainer: {
    margin: '20px',
    paddingBottom: '20px',
    textAlign: 'left',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  selectBox: {
    width: '120px',
    height: '40px',
    padding: '10px',
    marginLeft: '20px',
    marginRight: '20px',
  },
  button: {
    width: '120px',
    height: '40px',
    padding: '10px',
  },
  spacer: {
    width: '100%',
    height: '30px',
  },
});

const IMPORT_TYPE_ARRAY = Object.entries(IMPORT_TYPES);

const initialState = {
  selectedFile: null,
  language: 'none', //first value of language dropdown after removing 'all' option
  questionTopic: 'none', //first value of topic dropdown after removing 'all' option
  LMS: 'none',
  successPopup: false,
  errorPopup: false,
  successInfo: null,
  errorInfo: null,
};

const UploadQuestionsPage = ({ history }) => {
  const userLoggedIn = authenticateUser();
  if (!userLoggedIn) {
    history.push('/login');
  }
  const userObj = localStorage.getItem('QBUser');
  const user = JSON.parse(userObj);
  const user_id = user?.id;

  const classes = uploadQuestionsStyles();
  const [state, setState] = useState(initialState);

  const handleFileChange = (event) => {
    setState({
      ...state,
      selectedFile: event.target.files[0],
      loaded: 0,
    });
  };

  const handleLanguageChange = (event) => {
    setState({
      ...state,
      language: event.target.value,
    });
  };

  const handleTopicChange = (event) => {
    setState({
      ...state,
      questionTopic: event.target.value,
    });
  };

  const handleLMSChange = (event) => {
    setState({
      ...state,
      LMS: event.target.value,
    });
  };

  const handleSubmit = () => {
    // if there is missing information, show error message
    if (
      !state.LMS ||
      state.LMS === 'Choose' ||
      state.LMS === 'none' ||
      state.language === 'none' ||
      state.questionTopic === 'none' ||
      !state.selectedFile
    ) {
      let missingFields =
        'Missing fields: ' +
        (state.LMS === 'none' ? 'Learning Management System, ' : '') +
        (state.language === 'none' ? 'Programming Language, ' : '') +
        (state.questionTopic === 'none' ? 'Question Topic, ' : '') +
        (state.selectedFile ? '' : 'Course File');
      setState({
        ...state,
        errorPopup: true,
        errorInfo: missingFields,
      });
      return;
    }
    const data = new FormData();
    data.append('file', state.selectedFile);
    axios
      .post(
        `api/questions/import?programmingLanguage=${
          state.language ? state.language : ''
        }&topic=${state.questionTopic ? state.questionTopic : ''}&lms=${
          state.LMS !== 'none' ? state.LMS : ''
        }`,
        data,
        {
          headers: {
            id: user_id,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {

          if (res.data.numQuestions > 0) {
            setState({
              ...state,
              successInfo: 
                ` ${res.data.numQuestions} questions uploaded successfully.
                  ${res.data.numSkipped > 0? `${res.data.numSkipped} questions weren't uploaded. (${res.data.numUnsupported} unsupported question type, ${res.data.numError} other error)`: ''}
                `,
              successPopup: true,
              selectedFile: null,
            });
            //setTimeout(() => {
            //  history.push('/questions');
            //}, 3000);
          } else {
            setState({
              ...state,
              errorPopup: true,
              errorInfo:
              ` ${res.data.numQuestions} questions uploaded successfully.
                ${res.data.numSkipped > 0? `${res.data.numSkipped} questions weren't uploaded. (${res.data.numUnsupported} unsupported question type, ${res.data.numError} other error)`: ''}
              `,
              selectedFile: null,
            });
          }

        } else {
          if (res.data == null) {
            setState({
              ...state,
              errorInfo: 'No response from server. Please try again.',
              errorPopup: true,
            });
            return;
          }
          setState({
            ...state,
            errorInfo: res.data.errorMessage,
            errorPopup: true,
          });
        }
      });
  };

  const handleSuccessPopupClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({
      ...state,
      successPopup: false,
    });
  };

  const handleErrorPopupClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({
      ...state,
      errorPopup: false,
    });
  };

  const successPopupBox = (
    <NotificationBox
      isOpen={state.successPopup}
      OnCloseHandler={handleSuccessPopupClose}
      alertType="success"
      alertMessage={'Questions successfully uploaded:\n' + state.successInfo}
    />
  );

  const errorPopupBox = (
    <NotificationBox
      isOpen={state.errorPopup}
      OnCloseHandler={handleErrorPopupClose}
      alertType="error"
      alertMessage={'Error with upload!\n' + state.errorInfo}
    />
  );

  return (
    <div
      className={classes.pageContainer}
      success={state.formSuccess}
      error={state.formError}
    >
      {successPopupBox}
      {errorPopupBox}
      <h3>Upload questions for introductory Computer Science courses</h3>
      <p>
        Select your learning management system, the question bank file to
        upload, programming language and questions topic covered.
      </p>
      <p>
        After selecting above, you must click on Upload to complete the
        submission.
      </p>
      <div className={classes.spacer}></div>
      Learning Management System
      <select
        className={classes.selectBox}
        onChange={handleLMSChange}
        value={state.LMS}
      >
        <option key={'none'} value={'none'}>
          Choose
        </option>
        {IMPORT_TYPE_ARRAY.map(([key, expType]) => {
          return (
            <option key={key} value={key}>
              {expType}
            </option>
          );
        })}
      </select>
      <div className={classes.spacer}></div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          variant="contained"
          component="label"
          style={{ textTransform: 'none' }}
        >
          Choose a File
          <input
            type="file"
            hidden
            accept=".zip,.imscc"
            name="file"
            onChange={handleFileChange}
          />
        </Button>
        <p alignItems="center">
          <b>{state.selectedFile ? state.selectedFile.name : ''}</b>
        </p>
      </div>
      <div className={classes.spacer}></div>
      <DropdownMenu
        language={state.language}
        changeLanguageHandler={handleLanguageChange}
        questionTopic={state.questionTopic}
        changeTopicHandler={handleTopicChange}
      />
      <div className={classes.spacer}></div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          variant="contained"
          component="label"
          style={{ textTransform: 'none' }}
        >
          <b>Upload</b>
          <input hidden type="submit" onClick={handleSubmit} />
        </Button>
      </div>
    </div>
  );
};

export default UploadQuestionsPage;
