import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserList from '../components/Users/UserList';
import { authenticateUser } from '../service/auth';

const usersPageStyles = makeStyles({
  pageContainer: {
    margin: '20px',
    paddingBottom: '20px',
  },
  spacer: {
    width: '100%',
    height: '30px',
  },
});

const initialState = {
  users: [],
};

const UsersPage = ({ history }) => {
  const userLoggedIn = authenticateUser();
  if (!userLoggedIn) {
    history.push('/login');
  }
  if (!localStorage.getItem('QBUser')) {
    history.push('/questions');
  } else {
    const userObj = localStorage.getItem('QBUser');
    const user = JSON.parse(userObj);
    if (user.isAdmin === false) {
      history.push('/questions');
    }
  }

  const classes = usersPageStyles();
  const [state, setState] = useState(initialState);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    let user_id = JSON.parse(localStorage.getItem('QBUser')).id;
    const response = await fetch('api/admin/users', {
      headers: { id: user_id },
    });
    const data = await response.json();
    setState({
      ...state,
      users: data.users,
    });
  };

  return (
    <div className={classes.pageContainer}>
      <h3>Registered Users</h3>
      <UserList users={state.users} />
    </div>
  );
};

export default UsersPage;
