import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import UserRow from './UserRow';

const userListStyles = makeStyles({
  container: {
    margin: '20px 0 20px 0',
    overflowX: 'visible',
  },
  cellHeading: {
    fontWeight: 'bolder',
  },
  noQueFoundRow: {
    padding: '20px',
    textAlign: 'center',
  },
});

const UserList = ({
  users,
  //need somethting for onClick takes you tot page displaying all user data,
}) => {
  const classes = userListStyles();
  const renderUserRows =
    users ? 
      users.map((user,i) => {
        return (
          <UserRow
            key={i}
            rowIndex={i}
            userData={user}
          />
        );
      }) :
      ( 
        <tr>
          <td colSpan={4} className={classes.noQueFoundRow}>
            No users found!
          </td>
        </tr>
      );

  return (
    <TableContainer className={classes.container} component={Paper}>
      <Table aria-label="Users List" stickyHeader>
        <TableHead>
          <TableRow hover>
            <TableCell className={classes.cellHeading}>#</TableCell>
            <TableCell className={classes.cellHeading}>Name</TableCell>
            <TableCell className={classes.cellHeading}>Email</TableCell>
            <TableCell className={classes.cellHeading}>University</TableCell>
            <TableCell className={classes.cellHeading}>Admin</TableCell>
            <TableCell className={classes.cellHeading}>Verified</TableCell>
            <TableCell className={classes.cellHeading}>Details</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>{renderUserRows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserList;