import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import NotificationBox from '../components/NotificationBox';
import { login, authenticateUser } from '../service/auth';

const LoginStyles = makeStyles({
  pageContainer: {
    margin: '20px',
    marginLeft: '90px',
    paddingBottom: '20px',
  },
  container: {
    display: 'flex',
    textAlign: 'center',
  },
  gridSpacer: {
    flexBasis: '12.5%',
    maxWidth: '12.5%',
  },
  gridSpacerMiddle: {
    flexBasis: '5%',
    maxWidth: '5%',
  },
  item: {
    flexBasis: '35%',
    maxWidth: '35%',
    margin: '20px',
  },
  loginFormContainer: {
    //borderRight: '2px solid black',
  },
  itemFlexGrow: {
    flexGrow: 0,
    border: '1px solid red',
  },
  leftAlign: {
    textAlign: 'left',
  },
  spacer: {
    width: '100%',
    height: '10px',
  },
  form: {
    maxWidth: '350px',
  },
  label: {
    lineHeight: '2',
    textAlign: 'left',
    display: 'block',
    marginBottom: '13px',
    marginTop: '20px',
    color: 'black',
    fontSize: '16px',
    fontWeight: '700',
  },
  submitButton: {
    display: 'block',
    background: 'orange',
    color: 'white',
    textTransform: 'uppercase',
    cursor: 'pointer',
    border: 'none',
    marginTop: '20px',
    padding: '15px',
    fontSize: '18px',
    fontWeight: '600',
  },
  inputBox: {
    display: 'block',
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: '4px',
    border: '1px solid black',
    padding: '10px 15px',
    marginBottom: '10px',
    fontSize: '16px',
  },
  errorInfo: {
    color: 'red',
  },
});

const Login = ({ history }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const userLoggedIn = authenticateUser();
  if (userLoggedIn) {
    history.push('/questions');
  }
  const [state, setState] = useState({});
  const classes = LoginStyles();

  const onSubmit = async (userData, e) => {
    const loginData = {
      email: userData.email,
      password: userData.password,
    };
    const res = await login(loginData);
    if (res.data.success) {
      // set user info into local storage
      localStorage.setItem('QBUser', JSON.stringify(res.data.user));
      setState({
        ...state,
        successInfo: 'Login Successfully.',
        successPopup: true,
      });
      window.location.replace('/questions');
    } else {
      if (res.data == null) {
        setState({
          ...state,
          errorInfo: 'Error with login, please try again.',
          errorPopup: true,
        });
        return;
      }
      setState({
        ...state,
        errorInfo: res.data.errorMessage,
        errorPopup: true,
      });
    }
  };

  const handleSuccessPopupClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({
      ...state,
      successPopup: false,
    });
  };

  const handleErrorPopupClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({
      ...state,
      errorPopup: false,
    });
  };

  const successPopupBox = (
    <NotificationBox
      isOpen={state.successPopup}
      OnCloseHandler={handleSuccessPopupClose}
      alertType="success"
      alertMessage={state.successInfo}
    />
  );

  const errorPopupBox = (
    <NotificationBox
      isOpen={state.errorPopup}
      OnCloseHandler={handleErrorPopupClose}
      alertType="error"
      alertMessage={state.errorInfo}
    />
  );

  return (
    <div
      className={classes.pageContainer}
      success={state.formSuccess}
      error={state.formError}
    >
      {successPopupBox}
      {errorPopupBox}

      <h2>Computer Science Quiz Question Bank</h2>

      <div className={classes.spacer}></div>

      <div className={classes.container}>
        <div className={classes.gridSpacer}></div>

        <div className={classes.item}>
          <div className={classes.leftAlign}>
            <h3>About Computer Science Quiz Question Bank</h3>
            <p>
              <b>Computer Science Quiz Question Bank</b> is a repository of quiz
              questions for CS faculty in Canada.
            </p>
            <p>
              The project was funded by the{' '}
              <Link
                to={{ pathname: 'https://www.ecampusontario.ca/' }}
                target="_blank"
              >
                eCampus Ontario Virtual Learning Strategy (VLS)
              </Link>{' '}
            </p>
            <p>
              This app supports transferring questions to and from select
              Learning Management Systems.
            </p>
            <p>
              Visit the <Link to="/instruction">instructions</Link> page for
              answers to frequent questions.
            </p>
          </div>
        </div>

        <div className={classes.gridSpacerMiddle}></div>

        <div className={classes.item} className={classes.loginFormContainer}>
          <div className={classes.spacer}></div>
          <h3 className={classes.leftAlign}>Log In</h3>
          <p className={classes.leftAlign}>
            Please enter your email and password below. Don't have an account?{' '}
            <Link to="/register">Register</Link>
          </p>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <label className={classes.label}>Email:</label>
            <input
              className={classes.inputBox}
              type="email"
              {...register('email', { required: true })}
            />
            {errors?.email?.type === 'required' && (
              <em className={classes.errorInfo}>Please enter your email.</em>
            )}
            <label className={classes.label}>Password:</label>
            <input
              className={classes.inputBox}
              type="password"
              {...register('password', { required: true })}
            />
            {errors?.password?.type === 'required' && (
              <em className={classes.errorInfo}>Please enter your password.</em>
            )}
            <Link to="/forgot-password">Forgot Password?</Link>
            <button
              className={classes.submitButton}
              type="submit"
              onClick={handleSubmit}
            >
              Login
            </button>
          </form>
        </div>


        <div className={classes.gridSpacer}></div>
      </div>
    </div>
  );
};

export default Login;
