import React, { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';
import { makeStyles } from '@material-ui/core/styles';

const howtoStyles = makeStyles({
  pageContainer: {
    margin: '20px',
    paddingBottom: '20px',
    textAlign: 'left',
  },
});

const HowToPage = () => {
  const [content, setContent] = useState('');
  const classes = howtoStyles();

  useEffect(() => {
    import('./howto.md')
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setContent(res))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  });
  return (
    <div className={classes.pageContainer}>
      <Markdown>{content}</Markdown>
    </div>
  );
};

export default HowToPage;
