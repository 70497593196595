import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';

import QuestionRow from './QuestionRow';
import QuestionSelectToolbar from './QuestionSelectToolbar';

const questionListStyles = makeStyles({
  container: {
    margin: '20px 0 20px 0',
    overflowX: 'visible',
  },
  cellHeading: {
    fontWeight: 'bolder',
  },
  noQueFoundRow: {
    padding: '20px',
    textAlign: 'center',
  },
});

const QuestionList = ({
  questions,
  handleClick,
  handleSelectAllClick,
  isSelected,
  selectAllIndeterminate,
  selectAllChecked,
  selectedQuestions,
  /*isAdmin,
  userId,
  handleDeleteConfirmation,
  confirmDeleteQuestionId,*/
}) => {
  const classes = questionListStyles();
  const totalQuestionsCount = Object.keys(questions).length;
  const selectedQuestionsCount = selectedQuestions.length;
  const renderQuestionRows =
    totalQuestionsCount > 0 ? (
      Object.keys(questions).map((key, index) => {
        const id = key;
        const q = questions[key];
        return (
          <QuestionRow
            key={id}
            question={q}
            onCheckboxClick={(event) => handleClick(event, id)}
            isChecked={isSelected(id)}
            aria-checked={isSelected(id)}
            //isAdmin={isAdmin}
            //userId={userId}
            //handleDeleteConfirmation={() => {handleDeleteConfirmation(question.id)}}
          />
        );
      })
    ) : (
      <tr>
        <td colSpan={5} className={classes.noQueFoundRow}>
          No Questions found! Try changing filter options
        </td>
      </tr>
    );

  return (
    <TableContainer className={classes.container} component={Paper}>
      <QuestionSelectToolbar
        selectedQuestionsCount={selectedQuestionsCount}
        totalQuestionsCount={totalQuestionsCount}
      />
      <Table aria-label="Questions List" stickyHeader>
        <TableHead>
          <TableRow hover>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={selectAllIndeterminate}
                checked={selectAllChecked}
                onChange={handleSelectAllClick}
                inputProps={{ 'aria-label': 'select all questions' }}
              />
            </TableCell>
            <TableCell className={classes.cellHeading}>Question</TableCell>
            <TableCell className={classes.cellHeading}>Question Type</TableCell>
            <TableCell className={classes.cellHeading}>
              Correct Answer
            </TableCell>
            <TableCell className={classes.cellHeading}>
              Programming Language
            </TableCell>
            <TableCell className={classes.cellHeading}>Topic</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>{renderQuestionRows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default QuestionList;
