export const QUESTION_TYPES = {
  all: 'All',
  MC: 'Multiple Choice',
  TF: 'True/False',
  LA: 'Long Answer/Essay',
};

export const PROGRAMMING_LANGUAGES = {
  all: 'All',
  python: 'Python',
  java: 'Java',
  notApplicable: 'N/A',
};

export const QUESTION_TOPICS = {
  all: 'All',
  dataTypes: 'Data Types',
  casting: 'Casting',
  strings: 'Strings',
  booleanExpressions: 'Boolean Expressions',
  lists: 'Lists',
  sets: 'Sets',
  dictionaries: 'Dictionaries',
  ifElse: 'If Else',
  loops: 'Loops',
  functions: 'Functions',
  arrays: 'Arrays',
  classes: 'Classes',
  objects: 'Objects',
  inheritance: 'Inheritance',
  iterators: 'Iterators',
  modules: 'Modules',
  switch: 'Switch',
  methods: 'Methods',
  generalProgramming: 'General Programming',
  hardware: 'Hardware',
  binaryConversion: 'Binary Conversion',
  basicIO: 'Basic I/O',
  fileIO: 'File I/O',
  arithmeticExpressions: 'Arithmetic Expressions',
};

export const EXPORT_TYPES = {
  d2l: 'QTI - D2L',
  canvas: 'QTI - Canvas',
};

export const IMPORT_TYPES = {
  d2l: 'D2L',
  canvas: 'Canvas',
};

export const ASSOCIATED_UNIVERSITIES = {
  algomaUniversity: 'Algoma University',
  brockUniversity: 'Brock University',
  carletonUniversity: 'Carleton University',
  universityOfGuelph: 'University of Guelph',
  lakeheadUniversity: 'Lakehead University',
  laurentianUniversity: 'Laurentian University',
  mcmasterUniversity: 'McMaster University',
  nipissingUniversity: 'Nipissing University',
  ocadUniversity: 'OCAD University',
  ontarioTechUniversity: 'Ontario Tech University',
  universityOfOttawa: 'University of Ottawa',
  queensUniversity: 'Queens University',
  rmc: 'RMC',
  ryersonUniversity: 'Ryerson University',
  universityOfToronto: 'University of Toronto',
  trentUniversity: 'Trent University',
  universityOfWaterloo: 'University of Waterloo',
  westernUniversity: 'Western University',
  wilfredLaurierUniversity: 'Wilfred Laurier University',
  universityOfWindsor: 'University of Windsor',
  yorkUniversity: 'York University',
  other: 'Other',
};
