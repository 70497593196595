import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const footerStyles = makeStyles({
  footer: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'orange',
    padding: '5px',
    textAlign: 'center',
    color: 'white',
    width: '100%',
    height: '50px',
  },
  url: {
    color: 'white',
  },
});

const Footer = () => {
  const classes = footerStyles();
  return (
    <div>
      <footer className={classes.footer}>
        <Typography>
          This project is made possible with funding by the Government of
          Ontario and through eCampusOntario’s support of the Virtual Learning
          Strategy. To learn more about the Virtual Learning Strategy visit:{' '}
          <Link
            to={{ pathname: 'https://vls.ecampusontario.ca' }}
            target="_blank"
            className={classes.url}
          >
            eCampus Ontario VLS{' '}
          </Link>
          <br />
          The content of the question bank is licensed under an{' '}
          <Link
            to={{
              pathname:
                'https://vls.ecampusontario.ca/wp-content/uploads/2021/01/Ontario-Commons-License-1.0.pdf',
            }}
            target="_blank"
            className={classes.url}
          >
            Ontario Commons License
          </Link>
        </Typography>
      </footer>
    </div>
  );
};

export default Footer;
