import React, { useState, useEffect } from 'react';
import saveAs from 'file-saver';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import QuestionList from '../components/Question/QuestionList';
import QuestionFilter from '../components/Question/QuestionFilter';
import NotificationBox from '../components/NotificationBox';
import { authenticateUser } from '../service/auth';
import { EXPORT_TYPES } from '../consts';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
//import { MenuItem, FormControl, InputLabel, Select } from '@material-ui/core';
import { PROGRAMMING_LANGUAGES, QUESTION_TOPICS } from '../consts';

const PROGRAMMING_LANGUAGE_ARRAY = Object.entries(PROGRAMMING_LANGUAGES);
const QUESTION_TOPICS_ARRAY = Object.entries(QUESTION_TOPICS);

const questionStyles = makeStyles({
  pageContainer: {
    margin: '20px',
    paddingBottom: '20px',
  },
  selectBox: {
    width: '120px',
    height: '40px',
    padding: '10px',
    marginLeft: '10px',
    marginRight: '20px',
  },
  spacer: {
    width: '100%',
    height: '30px',
  },
  exportOptionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  filtersExportLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  lowerCaseButton: { textTransform: 'none' },
  disabledButton: {
    backgroundColor: '#B2B2B2',
  },
});

const initialState = {
  questions: {},
  questionType: 'all',
  programmingLanguage: 'all',
  questionTopic: 'all',
  exportType: 'd2l',
  selected: [],
  successPopup: false,
  errorPopup: false,
  errorInfo: '',
  successInfo: '',
  isAdmin: false,
  userId: null,
  confirmDeleteDialog: false,
  deleteAvailable: false,
  uploadedByMeChecked: false,
  editQuestionsDialog: false,
  editQuestionsTopic: 'all',
  editQuestionsLanguage: 'all',
  questionTopicChecked: false,
  programmingLanguageChecked: false,
};

const EXPORT_TYPE_ARRAY = Object.entries(EXPORT_TYPES);

const QuestionPage = ({ history }) => {
  //filter dictionary object function
  //source: https://stackoverflow.com/questions/5072136/javascript-filter-for-objects
  Object.filter = (obj, predicate) =>
    Object.keys(obj)
      .filter((key) => predicate(obj[key]))
      .reduce((res, key) => ((res[key] = obj[key]), res), {});

  const userLoggedIn = authenticateUser();
  if (!userLoggedIn) {
    history.push('/login');
  }
  const userObj = localStorage.getItem('QBUser');
  const user = JSON.parse(userObj);

  initialState.isAdmin = user.isAdmin === true;
  initialState.userId = user.id;

  const classes = questionStyles();
  const [state, setState] = useState(initialState);
  const rowCount = Object.keys(state.questions).length;
  const isSelected = (id) => state.selected.indexOf(id) !== -1;

  const setSelected = async (newSelected) => {
    // check if user is admin *or* owner of all selected questions
    let deleteAvailable = state.isAdmin;
    // iteration over questions needed to check if question is owned by user
    if (!deleteAvailable) {
      deleteAvailable = true;
      for (var i = 0; i < newSelected.length; i++) {
        const id = newSelected[i];
        //check if object state.questions has id as key
        if (state.questions[id] !== undefined) {
          //check if question is not owned by user
          if (state.questions[id].createdBy !== state.userId) {
            deleteAvailable = false;
            break;
          }
        }
      }
    }

    setState({
      ...state,
      selected: newSelected,
      deleteAvailable: deleteAvailable,
    });
  };

  const handleSelectAllClick = async (event) => {
    if (event.target.checked) {
      const newSelecteds = Object.keys(state.questions);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = async (event, id) => {
    const selectedIndex = state.selected.indexOf(id);
    let newSelected = state.selected;

    if (selectedIndex === -1) {
      // question is NOT selected already
      newSelected.push(id);
    } else if (selectedIndex > -1) {
      // question IS selected already
      newSelected.splice(selectedIndex, 1); // remove question from selected
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    getAllQuestions();
  }, []);

  const getAllQuestions = async () => {
    const response = await fetch('api/questions');
    const data = await response.json();

    const questionsObject = Object.assign(
      {},
      ...data.map((x) => ({ [x.id]: x }))
    );
    console.log('questionsObject: ' + JSON.stringify(questionsObject, null, 4));

    setState({
      ...state,
      questions: questionsObject,
    });
  };

  const exportQuestions = async () => {
    if (state.selected.length === 0) {
      setState({
        ...state,
        errorPopup: true,
        errorInfo: 'Error with download. No questions selected',
      });
      return;
    }

    const data = {
      questionType: state.questionType !== 'all' ? state.questionType : '',
      questionTopic: state.questionTopic !== 'all' ? state.questionTopic : '',
      programmingLanguage:
        state.programmingLanguage !== 'all' ? state.programmingLanguage : '',
      exportType: state.exportType,
      selectedQuestions: state.selected,
    };

    axios({
      url: 'api/questions/export/xml',
      method: 'POST',
      responseType: 'blob',
      data: data,
    }).then((res) => {
      saveAs(
        res.data,
        res.headers['content-disposition'].split('filename=')[1]
      );
      setState({
        ...state,
        selected: [],
        successPopup: true,
        successInfo: 'Questions downloaded successfully.',
      });
    });
  };

  const filterQuestions = async (
    questionType,
    programmingLanguage,
    questionTopic,
    uploadedByMe
  ) => {
    const selectedQuestionType = questionType !== 'all' ? questionType : '';
    const selectedProgrammingLanguage =
      programmingLanguage !== 'all' ? programmingLanguage : '';
    const selectedQuestionTopic = questionTopic !== 'all' ? questionTopic : '';
    const uploadedBy = uploadedByMe ? state.userId : '';

    const response = await fetch(
      `api/questions?questionType=${selectedQuestionType}&programmingLanguage=${selectedProgrammingLanguage}&questionTopic=${selectedQuestionTopic}&uploadedBy=${uploadedBy}`
    );
    const questions = await response.json();
    return Object.assign({}, ...questions.map((x) => ({ [x.id]: x })));
  };

  const handleQuestionTypeChange = async (e) => {
    setState({
      ...state,
      questionType: e.target.value,
      questions: await filterQuestions(
        e.target.value,
        state.programmingLanguage,
        state.questionTopic,
        state.uploadedByMeChecked
      ),
      selected: [],
    });
  };

  const handleProgrammingLanguageChange = async (e) => {
    setState({
      ...state,
      programmingLanguage: e.target.value,
      questions: await filterQuestions(
        state.questionType,
        e.target.value,
        state.questionTopic,
        state.uploadedByMeChecked
      ),
      selected: [],
    });
  };

  const handleQuestionTopicChange = async (e) => {
    setState({
      ...state,
      questionTopic: e.target.value,
      questions: await filterQuestions(
        state.questionType,
        state.programmingLanguage,
        e.target.value,
        state.uploadedByMeChecked
      ),
      selected: [],
    });
  };

  const handleUploadedByMeChange = async (e) => {
    setState({
      ...state,
      uploadedByMeChecked: e.target.checked,
      questions: await filterQuestions(
        state.questionType,
        state.programmingLanguage,
        state.questionTopic,
        e.target.checked
      ),
      selected: [],
    });
  };

  const handleExportTypeChange = (e) => {
    setState({
      ...state,
      exportType: e.target.value,
    });
  };

  const handleSuccessPopupClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({
      ...state,
      successPopup: false,
    });
  };

  const handleErrorPopupClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({
      ...state,
      errorPopup: false,
    });
  };

  const handleDeleteConfirmation = (questionId) => {
    setState({
      ...state,
      confirmDeleteDialog: true,
      confirmDeleteQuestionId: questionId,
    });
  };

  const handleDialogCancel = () => {
    setState({
      ...state,
      confirmDeleteDialog: false,
      editQuestionsDialog: false,
    });
  };

  const handleEditTopicChange = (e) => {
    setState({
      ...state,
      editTopic: e.target.value,
    });
  };

  const deleteQuestions = async () => {
    let user_id = JSON.parse(localStorage.getItem('QBUser')).id;
    let questionsToDelete = state.selected;

    let headers = { id: user_id, questionIds: questionsToDelete };
    console.log('headers: ', JSON.stringify(headers));

    const response = await fetch(`/api/questions/delete`, {
      headers: {
        id: user_id,
        questionids: questionsToDelete,
      },
    });
    const data = await response.json();
    console.log(data);

    if (data.success) {
      setState({
        ...state,
        successPopup: true,
        successInfo: `Deleted ${data.numDeleted} questions successfully.`,
        questions: Object.filter(state.questions, (q) => {
          return !questionsToDelete.includes(q.id.toString());
        }),
        confirmDeleteDialog: false,
        selected: [],
      });
    } else {
      setState({
        ...state,
        errorPopup: true,
        errorInfo:
          'Error deleting questions. ' +
          (data.errorMessage ? data.errorMessage : ''),
        confirmDeleteDialog: false,
      });
    }
  };

  const editQuestions = async () => {
    if (
      !(
        (state.questionTopicChecked && state.editQuestionsTopic !== 'all') ||
        (state.programmingLanguageChecked &&
          state.editQuestionsLanguage !== 'all')
      )
    ) {
      setState({
        ...state,
        errorPopup: true,
        errorInfo: 'Please select at least one field to edit.',
        editQuestionsDialog: true,
      });
      return;
    }

    let user_id = JSON.parse(localStorage.getItem('QBUser')).id;
    let questionsToEdit = state.selected;

    let headers = {
      id: user_id,
      questionIds: questionsToEdit,
      ...(state.questionTopicChecked &&
        state.editQuestionsTopic !== 'all' && {
          topic: state.editQuestionsTopic,
        }),
      ...(state.programmingLanguageChecked &&
        state.editQuestionsLanguage !== 'all' && {
          programminglanguage: state.editQuestionsLanguage,
        }),
    };

    const response = await fetch(`/api/questions/edit`, {
      headers: headers,
    });

    //console.log('headers: ', JSON.stringify(headers));

    const data = await response.json();
    //console.log(data);

    if (data.success) {
      setState({
        ...state,
        successPopup: true,
        successInfo: `Edited ${data.numEdited} questions successfully.`,
        editQuestionsDialog: false,
        selected: [],
        questions: await filterQuestions(
          state.questionType,
          state.programmingLanguage,
          state.questionTopic,
          state.uploadedByMeChecked
        ),
        editQuestionsTopic: 'all',
        editQuestionsLanguage: 'all',
        questionTopicChecked: false,
        programmingLanguageChecked: false,
      });
    } else {
      setState({
        ...state,
        errorPopup: true,
        errorInfo:
          'Error editing questions. ' +
          (data.errorMessage ? data.errorMessage : ''),
        editQuestionsDialog: false,
      });
    }
  };

  const successPopupBox = (
    <NotificationBox
      isOpen={state.successPopup}
      OnCloseHandler={handleSuccessPopupClose}
      alertType="success"
      alertMessage={state.successInfo}
    />
  );

  const errorPopupBox = (
    <NotificationBox
      isOpen={state.errorPopup}
      OnCloseHandler={handleErrorPopupClose}
      alertType="error"
      alertMessage={state.errorInfo}
    />
  );

  return (
    <div className={classes.pageContainer}>
      {successPopupBox}
      {errorPopupBox}
      <h3>Quiz Questions for Introductory Computer Science Courses</h3>
      <div className={classes.spacer}></div>
      <div className={classes.filtersExportLine}>
        {/* Filters */}
        <QuestionFilter
          questionType={state.questionType}
          changeQuestionTypeHandler={handleQuestionTypeChange}
          programmingLanguage={state.programmingLanguage}
          changeLanguageHandler={handleProgrammingLanguageChange}
          questionTopic={state.questionTopic}
          changeTopicHandler={handleQuestionTopicChange}
          uploadedByMeChecked={state.uploadedByMeChecked}
          changeUploadedByMeHandler={handleUploadedByMeChange}
          userId={state.userId}
        />
        <div className={classes.exportOptionsContainer}>
          <Button
            onClick={handleDeleteConfirmation}
            variant="contained"
            disabled={state.selected.length === 0 || !state.deleteAvailable}
            style={{
              backgroundColor:
                state.selected.length !== 0 && state.deleteAvailable
                  ? '#F44336'
                  : '#B2B2B2',
              color: 'white',
              cursor: 'pointer',
              marginRight: '15px',
              flexDirection: 'column',
            }}
            classes={{
              label: classes.lowerCaseButton,
            }}
          >
            Delete Questions
          </Button>
          <Button
            onClick={() => setState({ ...state, editQuestionsDialog: true })}
            variant="contained"
            disabled={state.selected.length === 0 || !state.deleteAvailable}
            style={{
              backgroundColor:
                state.selected.length !== 0 && state.deleteAvailable
                  ? '#4CAF50'
                  : '#B2B2B2',
              color: 'white',
              cursor: 'pointer',
              marginRight: '15px',
              flexDirection: 'column',
            }}
            classes={{
              label: classes.lowerCaseButton,
            }}
          >
            Edit Questions
          </Button>
          Export Type
          <select
            className={classes.selectBox}
            onChange={handleExportTypeChange}
            value={state.exportType}
          >
            {EXPORT_TYPE_ARRAY.map(([key, expType]) => {
              return (
                <option key={key} value={key}>
                  {expType}
                </option>
              );
            })}
          </select>
          <button
            style={{ width: '120px', height: '40px', cursor: 'pointer' }}
            onClick={exportQuestions}
          >
            Export Questions
          </button>
        </div>
      </div>
      <QuestionList
        questions={state.questions}
        handleClick={handleClick}
        handleSelectAllClick={handleSelectAllClick}
        isSelected={isSelected}
        selectAllIndeterminate={
          state.selected.length > 0 && state.selected.length < rowCount
        }
        selectAllChecked={rowCount > 0 && state.selected.length === rowCount}
        selectedQuestions={state.selected}
        //isAdmin={state.isAdmin}
        //userId={state.userId}
        //handleDeleteConfirmation={handleDeleteConfirmation}
      />

      <Dialog
        open={state.confirmDeleteDialog}
        onClose={handleDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Confirm Question Deletion?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to delete {state.selected.length} question(s). Are you
            sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel} color="error">
            Cancel
          </Button>
          <Button
            onClick={deleteQuestions}
            variant="contained"
            style={{
              backgroundColor: '#F44336',
              color: 'white',
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={state.editQuestionsDialog}
        onClose={handleDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Edit Questions'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Editing {state.selected.length} question(s).
          </DialogContentText>

          {/* programming language checkbox */}
          <div className={classes.checkboxContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.programmingLanguageChecked}
                  onChange={(e) => {
                    setState({
                      ...state,
                      programmingLanguageChecked: e.target.checked,
                    });
                  }}
                  name="programmingLanguageChecked"
                  color="primary"
                />
              }
              label="Change Programming Language"
            />
            <select
              className={classes.selectBox}
              onChange={(e) => {
                setState({ ...state, editQuestionsLanguage: e.target.value });
              }}
              value={state.editQuestionsLanguage}
              disabled={!state.programmingLanguageChecked}
            >
              {PROGRAMMING_LANGUAGE_ARRAY.map(([key, queType]) => {
                if (key === 'all')
                  return (
                    <option key={'all'} value={'all'}>
                      {''}
                    </option>
                  );
                return (
                  <option key={key} value={key}>
                    {queType}
                  </option>
                );
              })}
            </select>
          </div>

          {/* question topic checkbox */}
          <div className={classes.checkboxContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.questionTopicChecked}
                  onChange={(e) => {
                    setState({
                      ...state,
                      questionTopicChecked: e.target.checked,
                    });
                  }}
                  name="questionTopicChecked"
                  color="primary"
                />
              }
              label="Change Topic"
            />
            <select
              className={classes.selectBox}
              onChange={(e) => {
                setState({ ...state, editQuestionsTopic: e.target.value });
              }}
              value={state.editQuestionsTopic}
              disabled={!state.questionTopicChecked}
            >
              {QUESTION_TOPICS_ARRAY.map(([key, queType]) => {
                if (key === 'all')
                  return (
                    <option key={'all'} value={'all'}>
                      {''}
                    </option>
                  );
                return (
                  <option key={key} value={key}>
                    {queType}
                  </option>
                );
              })}
            </select>
          </div>

          <div className={classes.spacer}></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel} color="error">
            Cancel
          </Button>
          <Button
            onClick={editQuestions}
            variant="contained"
            style={{
              backgroundColor: '#F44336',
              color: 'white',
            }}
            autoFocus
          >
            Make Changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default QuestionPage;
